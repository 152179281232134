@import './reboot';
@import './panel';
@import './control';

@import './components/loading_screen';
@import './components/logo';
@import './components/info_section';
@import './components/control_panel';
@import './components/risks_list';
@import './components/risks_list_item';
@import './components/switch';
@import './components/map';
@import './components/pitch_mode_toggle';

#app {
  height: 100vh;
  position: relative;
  width: 100vw; }
