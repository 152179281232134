.info_section {
  bottom: 35px;
  color: #333;
  font-size: 12px;
  opacity: .95;
  position: absolute;
  right: 10px;
  user-select: none;
  width: 135px;
  z-index: 1; }

.info_section-item {
  display: flex; }

.info_section-item_label {
  flex: 1; }
