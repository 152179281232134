.risks_list {
  flex: 1;
  overflow: scroll;
  position: relative;
  transform: translateZ(0); }

.risks_list-row {
  display: flex;
  list-style: none;
  padding: 4px 6px;

  span {
    flex: 1;
    max-width: 33%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis; } }

.risks_list-header,
.risks_list-body,
.risks_list-footer {
  margin: 0;
  padding: 0; }

.risks_list-header,
.risks_list-footer {
  background-color: rgba(255, 255, 255, .95);
  position: sticky;
  z-index: 1; }

.risks_list-header {
  top: 0; }

.risks_list-footer {
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  padding: 5px; }

.risks_list-footer_info {
  color: #aaa;
  padding-top: 2px;
  width: 30%; }
