.risks_list_item {
  &.is-clickable {
    color: #fff;
    cursor: pointer;

    &.is-selected {
      background-color: #f0f; // match it with store/layerTypes.js
      color: #eee;
      transform: scaleX(.97) scaleY(.9); }

    &:hover {
      background-color: #e0e;
      color: #fff; } }

  // when you change these colors, be sure that they match
  // defined ones in store/layerTypes.js
  &.is-0 {
    background-color: #fff41f;
    color: #333; }
  &.is-1 {
    background-color: #fde71f;
    color: #333; }
  &.is-2 {
    background-color: #f9d620;
    color: #333; }
  &.is-3 {
    background-color: #f6c022;
    color: #333; }
  &.is-4 {
    background-color: #f2a823; }
  &.is-5 {
    background-color: #ed8e24; }
  &.is-6 {
    background-color: #e97526; }
  &.is-7 {
    background-color: #e55d26; }
  &.is-8 {
    background-color: #e14828; }
  &.is-9 {
    background-color: #dd3628; }
  &.is-10 {
    background-color: #db2929; } }
