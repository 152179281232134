.control_panel {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 220px;
  width: 20vw;

  &-input {
    margin: 0 0 20px 0; } }

.control_panel-input_label {
  display: block;
  font-weight: bold; }

.control_panel-input_label_hint {
  color: #aaa;
  font-size: 12px;
  font-weight: normal;
  margin-left: .5em; }
