.switch-field {
  display: flex;
  overflow: hidden;
}

.switch-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.switch-field input {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.switch-field label {
  background-color: #ececec;
  border: 1px solid rgba(0, 0, 0, .2);
  border-right: none;
  color: rgba(0, 0, 0, .6);
  display: inline-block;
  flex: 1;
  font-size: 12px;
  font-weight: normal;
  padding: 6px 12px;
  text-align: center;
  text-shadow: none;
  transition: all 0.1s ease-in-out;
  width: 33%;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #1955a5;
  box-shadow: none;
  color: #fff;
}

.switch-field label:first-of-type {
  border-radius: 3px 0 0 3px;
}

.switch-field label:last-of-type {
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0 3px 3px 0;
}
