.loading_screen {
  align-items: center;
  background-color: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: {
    property: background-color, backdrop-filter, opacity;
    duration: 1s;
    timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22); }
  // https://easings.net/en#easeInQuart
  width: 100vw;
  z-index: 65535;

  &.is-blurred {
    //background: rgba(150, 200, 160, 0.3)
    //backdrop-filter: saturate(50%) blur(20px)
    opacity: 1; } }

.loading_screen-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  justify-content: center;
  width: 50vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 75px 55px;
  background: rgba(184, 240, 193, 0.3);
  backdrop-filter: saturate(90%) blur(5px);
  border: 0.1px solid rgba(145, 186, 126, 0.4);
  border-radius: 7px; }

.loading_screen-message_text,
.loading_screen-errors {
  margin-top: 1em;

  &.is-centered {
    text-align: center; }

  &.is-progress {
    color: black; } }

.loading_screen-spacer {
  flex-grow: 1; }

.loading_screen-error {
  color: red;
  list-style: none; }
