.panel {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
  font-size: 14px;
  overflow: hidden;
  padding: 15px;
  position: absolute;
  user-select: none;
  z-index: 1;

  &.is-left {
    bottom: 35px;
    left: 10px;
    top: 10px; } }
